:root {
    --green: #52c41a;
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --veryLightGray: hsl(0, 0%, 98%);
    --weight1: 200;
    --weight2: 400;
    --weight3: 600;
}

.checking-in-modal > .ant-modal-content > .ant-modal-footer {
    background-color: #ffffff;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
}

.checking-in-modal > .ant-modal-content > .ant-modal-footer {
    background-color: #ffffff;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
}

.checking-in-container {
    height: 100% !important;

    overflow: auto;
    font-family: "Poppins", sans-serif;
}

.checking-in-page-header {
    background: none;
    text-align: center;
}

.checking-in-business-info-container {
    padding: 10px;
    margin-top: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.checking-in-business-name {
    font-weight: bold;
}

.checking-in-business-address {
    font-size: 20px;
}

.checking-in-input-number input {
    /* background-color: #001428; */
    /* color: #fff; */
    font-size: 35px;
}

.checking-in-input-number {
    min-width: 100%;
    /* background-color: #001428; */
    line-height: 40px;
    /* border: 1px solid #fff; */
}

.input-label {
    font-size: 20px;
}

.checking-in-phone-error {
    margin-top: 10px;
    /* /* margin-top: -20px; */

    color: var(--red) !important;
    text-align: left;
}

.box {
    user-select: none;
    cursor: pointer;
    border-radius: 15px;
    padding: 30px;
    margin-top: 20px;
    background-color: #fff;
    transition: 0.2s;
}

.box:hover {
    box-shadow: 0px 20px 30px -20px var(--grayishBlue);
}

/* .box div:not(:last-child) {
    margin-bottom: 10px;
} */

.box h3 {
    margin: 0;
}

.box p {
    color: var(--grayishBlue);
    /* margin: 0 0 0 15px; */
}

/* img {
    float: right;
} */

@media (max-width: 450px) {
    .box {
        padding-top: 20px;
        padding-bottom: 30px;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .box {
        /* text-align: center; */
        /* min-height: 250px; */
        padding: 30px;
    }
}

.cyan {
    border-top: 3px solid var(--cyan);
}

.red {
    border-top: 3px solid var(--red);
}
.blue {
    border-top: 3px solid var(--blue);
}
.orange {
    border-top: 3px solid var(--orange);
}

.green {
    border-top: 3px solid var(--green);
}

.text-green {
    color: var(--green);
}

.checking-in-float-right {
    float: right;
}

.select-services-sticky-container {
    padding: 15px 0 10px 0;
    background-color: #f0f2f5;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
}

.service-container {
    user-select: none;
    cursor: pointer;
    border-radius: 15px;
    padding: 30px;
    background-color: #fff;
    transition: 0.2s;
}

.modal-service {
    cursor: pointer;
    position: relative;
    width: 100%;
    margin: 5px 10px;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    padding: 15px;
    transition: 0.2s;
}

.modal-service:hover {
    box-shadow: 0 8px 20px #e6e6e6 !important;
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
    transition-duration: 0.1s;
}

.modal-service-selected {
    border: 1px solid #00c2cb;
    box-shadow: 0 8px 20px #e6e6e6 !important;
    transform: scale(1.01);
    background-color: #d6fdff;
    -webkit-transform: scale(1.01);
}

.selected-service {
    padding: 10px 15px;
    margin: 30px 0;
    background-color: #d6fdff;
    border: 1px solid #00c2cb;
    border-radius: 6px;
    transition: 0.2s;
}

.selected-service:hover {
    box-shadow: 0 8px 20px #c4c4c4 !important;
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
    transition-duration: 0.1s;
}

.checking-in-modal {
    max-width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

.checking-in-select-staff-for-service {
    border-radius: 10px !important;
}

.checking-in-select-staff-for-service .ant-select-selector {
    border-radius: 10px !important;
}

.less-margin-bottom {
    margin-bottom: 10px;
}

.select-gender-option {
    font-size: 17px !important;
    line-height: 35px !important;
    /* border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent; */
}

.birthday-cascader {
    border-radius: 5px !important;
}

.birthday-cascader-option .ant-cascader-menu {
    height: 250px !important;
    border-radius: 5px;
    font-size: 17px;
}

.birthday-cascader-option .ant-cascader-menu .ant-cascader-menu-item {
    line-height: 35px;
    border-radius: 5px;
}

.booking-staffList > .ant-list-header {
    padding: 0;
}

.booking-list-item {
    padding: 20px 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.1s;
}

.booking-list-item:hover {
    background-color: #e5f1ff;
}

.grecaptcha-badge {
    opacity: 0;
}