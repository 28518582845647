/* @import '~antd/dist/antd.css'; */
@import '~antd/dist/antd.variable.min.css';

html,
body {
  height: 100% !important;
  margin: 0;
  background-color: #f0f0f0;
}

body {
  /* position: fixed; */
  /* font-family:  sans-serif !important; */
  font-family: 'Poppins', sans-serif;
  /* font-family: Nunito, Helvetica Neue, Helvetica, Arial, sans-serif; */
  /* font-weight: 500; */
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke-width: 0.2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
  background-color: #f0f0f0;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8c8c8c;
}

/* .ant-cascader-input {
  min-height: 50px !important;
} */

.ant-cascader-menu {
  min-width: 200px !important;
  min-height: 250px !important;
}


.success-message > .ant-message-notice-content {
  background-color: #F6FFED;
 
  border-radius: 5px;
  border: 1px solid #b7eb8f;
  padding: 10px;
}

.error-message > .ant-message-notice-content {
  background-color: #FEF2F0;
 
  border-radius: 5px;
  border: 1px solid #ffccc7;
  padding: 10px;
}
