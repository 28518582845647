.loading-component {
    text-align: center;
    position: absolute;
    right: 50%;
    top: 30%;
/*    
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0; */
  }